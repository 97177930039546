const { 
  v4: uuidv4,
} = require('uuid');

export class ActiveAppointments {
  static async fetchActiveAppointments() {
    for (const activeApptEl of document.querySelectorAll('.js-active-appointment')) {
      ActiveAppointments.fetchActiveAppointmentsForEl(activeApptEl);
    }
  }

  static async fetchActiveAppointmentsForEl(activeAppointmentEl) {
    if (activeAppointmentEl && activeAppointmentEl.dataset && activeAppointmentEl.dataset.activeAppointmentEndpoint && activeAppointmentEl.dataset.fetched == "false") {
      try {
        const response = await fetch(activeAppointmentEl.dataset.activeAppointmentEndpoint, {
          headers: {
            'Client': 'Yext',
            'ClientApplicationName': 'Locations Queue',
            'RequestId': uuidv4()
          }
        });
        const responseJson = await response.json();
        const activeAppointmentsNumEl = activeAppointmentEl.querySelector('.js-active-appointment-number');
        if (activeAppointmentsNumEl && responseJson.activeAppointmentCount !== undefined) {
          activeAppointmentsNumEl.innerHTML = responseJson.activeAppointmentCount;
          activeAppointmentEl.classList.remove('u-hidden');
        }
        activeAppointmentEl.setAttribute('data-fetched', 'true');
      }
      catch (err) {
        console.log(`Fetching ${activeAppointmentEl.dataset.activeAppointmentEndpoint} failed: ${err}`);
      }
    }
  }
}
