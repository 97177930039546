import 'babel-polyfill';
import 'script-loader!node_modules/jquery/dist/jquery.min.js';

// uncomment if you need to do expand/collapse:
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { GoogleAdwords } from 'js/components/GoogleAdwords/index.js'; /* TODO: remove if unused */
import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js'; /* TODO: remove if unused */
import { LocationHours } from 'js/components/LocationHours/index.js'; /* TODO: remove if unused */
import { ActiveAppointments } from 'js/common/modules/ActiveAppointments.js';


Global.init();
GoogleAdwords.init();
GoogleAnalytics.enableAutotracking('yext');

OnReady(() => {
  LocationHours.loadAndRun();

  let callback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        let apptEl = entry.target.querySelector('.js-active-appointment');
        if (apptEl) {
          ActiveAppointments.fetchActiveAppointmentsForEl(apptEl);
        }
      } 
    });
  };

  const observer = new IntersectionObserver(callback);
  
  let appts = document.querySelectorAll('.Teaser--directory');
  for (let appt of appts) {
    observer.observe(appt);
  }

  // - The un-minified file is different than the minified one :/
  // - Uncomment the following if using JVector map
  // VectorMap.autorun();
});
